
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { userService } from "@/services/api/user.service";
import { User } from "@/models/entities/user.interface";
import { CellType, DataTable, Header, TableAction } from "@zelando/vuelando";
import AuthModule from "@/store/modules/auth.module";
import { subscriptionPlansService } from "@/services/api/subscription-plan.service";
import { PromotionPlans } from "@/models/entities/subscription-plan.interface";
import { BillingAddress } from "@/models/entities/billing-address.interface";
import { Country } from "@/models/entities/country.interface";
import { countryService } from "@/services/api/country.service";
import { SnackbarModule } from "@zelando/vuelando";
import { downloadXmlService } from "@/services/api/generate-xml.service";

const snackbarModule = getModule(SnackbarModule, store);
const authModule = getModule(AuthModule, store);

@Component
export default class Administration extends Vue {
  private user = [] as User[];
  private profileRoleID = -1;
  private loadOnlyEnabled = false;
  private modifyDialog = false;
  private billingAddressDialog = false;
  private modifyDialogUser = {} as User;
  private plans = [] as PromotionPlans[];
  private selectedUserID = -1;
  private billingAddress: BillingAddress = {} as BillingAddress;

  private headers: Header[] = [
    {
      text: this.$tc("administration.UserId"),
      value: "userID",
      filterable: true,
      sortable: true,
      cellType: CellType.DEFAULT,
    },
    {
      text: this.$tc("administration.IsEnabled"),
      value: "isEnabled",
      filterable: false,
      sortable: false,
      cellType: CellType.BOOLEAN,
    },
    {
      text: this.$tc("administration.Email"),
      value: "email",
      filterable: true,
      sortable: true,
      cellType: CellType.DEFAULT,
    },
    {
      text: this.$tc("administration.PhoneNumber"),
      value: "phoneNumber",
      filterable: true,
      sortable: true,
      cellType: CellType.DEFAULT,
    },
    {
      text: this.$tc("administration.CreatedOn"),
      value: "createdOn",
      filterable: true,
      sortable: true,
      cellType: CellType.DATE,
    },
    {
      text: this.$tc("administration.Expiration"),
      value: "expiration",
      filterable: true,
      sortable: true,
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("administration.LastLogin"),
      value: "lastLogin",
      filterable: true,
      sortable: true,
      cellType: CellType.DATE,
    },
    {
      text: this.$tc("administration.Role"),
      value: "roleDescription",
      filterable: true,
      sortable: true,
      cellType: CellType.DEFAULT,
    },
    {
      text: "",
      value: "action",
      filterable: false,
      sortable: false,
      cellType: CellType.CUSTOM,
    },
  ];

  private tableOptions: DataTable<User> = {
    key: "userID",
    loading: false,
    search: "",
    actions: [
      {
        name: "edit",
        icon: "trash",
        tooltip: this.$tc("home.Delete"),
        size: "extra-small",
      } as TableAction,
    ],
    headers: this.headers,
    values: this.user,
    itemsPerPage: 15,
  };

  private countries: Country[] = [];

  async created(): Promise<void> {
    if (authModule.profileRoleID !== 1) {
      this.$router.push({
        name: "Logout",
      });
    }
    let userData = await userService.GetAllUsers(
      this.profileRoleID,
      this.loadOnlyEnabled
    );
    this.user = userData.data;
    this.$set(this.tableOptions, "values", this.user);
    var p = await subscriptionPlansService.GetAllOffert();
    this.plans = p.data;
    this.countries = (await countryService.GetAllCountry()).data;
  }

  private async openModifyUser(userID: number) {
    let userData = await userService.GetUserByID(userID);
    this.modifyDialogUser = userData.data;
    this.modifyDialogUser.expiration = new Date(
      this.modifyDialogUser.expiration
    ).toFormat("dd/MM/yyyy");
    this.modifyDialog = true;
  }
  private async closeDialog() {
    await userService.GetAllUsers(this.profileRoleID, this.loadOnlyEnabled);
    this.$set(this.tableOptions, "values", this.user);
    this.modifyDialog = false;
  }

  private async saveUser() {
    const date = this.modifyDialogUser.expiration.split("/");
    this.modifyDialogUser.expiration = new Date(
      new Date(date[2] + "-" + date[1] + "-" + date[0])
    ).toFormat("yyyy-MM-dd");
    await userService.UpdateUser(this.modifyDialogUser).then((x) => {
      if (x.status == 200) {
        snackbarModule.showSnackbar({
          message: this.$tc("administration.ModifiedOK"),
          type: "success",
          timer: 5000,
        });
      }
    });
    await this.closeDialog();
    await userService
      .GetAllUsers(this.profileRoleID, this.loadOnlyEnabled)
      .then((x) => {
        if (x.status == 200) {
          this.user = x.data;
          this.$set(this.tableOptions, "values", this.user);
        }
      });
  }

  private async openBillingAddress(userID: number) {
    this.selectedUserID = userID;
    this.billingAddress = (
      await userService.GetBillingAddressAdmin(userID)
    ).data;
    this.billingAddressDialog = true;
  }

  private async closeBillingAddress() {
    this.billingAddressDialog = false;
    this.selectedUserID = -1;
  }

  private async saveBillingAddress() {
    await userService
      .AdminUpdateBillingAddress(this.billingAddress, this.selectedUserID)
      .then((x) => {
        if (x.status == 200) {
          snackbarModule.showSnackbar({
            message: this.$tc("administration.ModifiedOK"),
            type: "success",
            timer: 5000,
          });
        }
      });
    this.billingAddressDialog = false;
    this.selectedUserID = -1;
  }

  private isCancelMonthlyDownloadsOpen = false;
  private userMonthlyDownloadsCount = 0;

  get cancelMonthlyDownloadsActions(): unknown[] {
    const defActions = [
      {
        slotText: this.$tc("administration.Cancel"),
        plainType: "primary",
        action: () => this.closeCancelMonthlyDownloads(),
      },
      {
        slotText: this.$tc("administration.CancelMonthlyDownloadsBtnConfirm"),
        type: "primary-color",
        disabled: !this.userMonthlyDownloadsCount,
        action: () => this.cancelMonthlyDownloadsSubmit(),
      },
    ];
    if (!(this.userMonthlyDownloadsCount > 0)) {
      defActions.pop();
    }
    return defActions;
  }

  get cancelMonthlyDownloadsTitle(): string {
    if (this.isCancelMonthlyDownloadsOpen) {
      const userData = this.user
        .filter((x) => x.userID === this.selectedUserID)
        .map((y) => y.email);
      return userData.length > 0 ? userData[0] : "";
    }
    return "";
  }

  get cancelMonthlyDownloadsDescription(): string {
    if (this.isCancelMonthlyDownloadsOpen && this.userMonthlyDownloadsCount) {
      return (
        this.$tc("administration.CancelMonthlyDownloadsDescription") +
        this.userMonthlyDownloadsCount
      );
    } else {
      return this.$tc("administration.CancelMonthlyDownloadsDescriptionNoData");
    }
  }

  private async cancelMonthlyDownloads(userID: number) {
    this.selectedUserID = userID;
    const res = await downloadXmlService.getMonthDownload(userID);
    this.userMonthlyDownloadsCount = res.data;
    this.isCancelMonthlyDownloadsOpen = true;
  }

  private async cancelMonthlyDownloadsSubmit() {
    const res = await downloadXmlService.clearMonthDownload(
      this.selectedUserID
    );
    snackbarModule.showSnackbar({
      message: this.$tc("administration.CancelMonthlyDownloadsOK"),
      type: res.status === 200 ? "success" : "error",
      timer: 5000,
    });
    this.closeCancelMonthlyDownloads();
  }

  private async closeCancelMonthlyDownloads() {
    this.selectedUserID = -1;
    this.isCancelMonthlyDownloadsOpen = false;
  }
}
