import { PromotionPlans } from "@/models/entities/subscription-plan.interface";
import { ApiService } from "@zelando/vuelando";

const controller = "subscriptionplans";

class SubscriptionPlansService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  GetAllOffert() {
    return this.getAll<PromotionPlans[]>(`${controller}/getall`);
  }

  GetById(roleId: number) {
    return this.getAll<PromotionPlans>(
      `${controller}/getbyid?roleId=${roleId}`
    );
  }

  GetExpiration() {
    return this.getAll<string>(`${controller}/getexpiration`);
  }
}

export const subscriptionPlansService = new SubscriptionPlansService();
