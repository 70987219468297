import { GenerateXml } from "@/models/entities/generate-xml.interface";
import { ApiService } from "@zelando/vuelando";

const controller = "downloadxml";

class GenerateXmlService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  GetDownloadXml(generateXml: GenerateXml, firstTime: boolean) {
    generateXml.firstTime = firstTime;
    return this.post<GenerateXml, string>(
      `${controller}/downloadxml`,
      generateXml
    );
  }

  getMonthDownload(userID: number) {
    return this.getAll<number>(`${controller}/getMonthDownload/${userID}`);
  }

  clearMonthDownload(userID: number) {
    return this.post<number, void>(
      `${controller}/clearMonthDownload/${userID}`,
      null
    );
  }
}

export const downloadXmlService = new GenerateXmlService();
